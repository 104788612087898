<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly">
      <v-flex xs12 max-width-1400 full-width ma-auto px-2 mb-5>
        <h1
          class="primary--text graphik-bold mb-2 mt-0"
          :class="{'font-35': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('how_it_works_title') }}</h1>
        <p
          class="dark-grey--text graphik-light mb-5"
          :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('how_it_works_description') }}</p>

        <v-container fluid ma-0 pa-0 grid-list-xl>
          <v-layout row wrap fill-height>
            <v-flex xs12 md6 lg4 text-xs-center fill-height my-3 px-5 v-for="(step, index) in steps" :key="index">
              <v-img
                height="100"
                contain
                class="ma-auto"
                :src="step.image"
                :lazy-src="step.image"
              ></v-img>

              <p class="dark-grey--text graphik-bold font-17 my-3">{{ step.title }}</p>

              <p class="dark-grey--text graphik-light font-15 mb-0">{{ step.text }}</p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'HowItWorks',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_HOW_IT_WORKS,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_HOW_IT_WORKS
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_HOW_IT_WORKS
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_HOW_IT_WORKS
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_HOW_IT_WORKS
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_HOW_IT_WORKS
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/comofunciona'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/comofunciona'
      }
    ]
  },
  data () {
    return {
      steps: [
        {
          image: require('@/assets/img/icons/blue/question.png'),
          title: this.$ml.get('how_it_works_step_1_title'),
          text: this.$ml.get('how_it_works_step_1_description')
        },
        {
          image: require('@/assets/img/icons/blue/talk.png'),
          title: this.$ml.get('how_it_works_step_2_title'),
          text: this.$ml.get('how_it_works_step_2_description')
        },
        {
          image: require('@/assets/img/icons/blue/house_car.png'),
          title: this.$ml.get('how_it_works_step_3_title'),
          text: this.$ml.get('how_it_works_step_3_description')
        },
        {
          image: require('@/assets/img/icons/blue/search.png'),
          title: this.$ml.get('how_it_works_step_4_title'),
          text: this.$ml.get('how_it_works_step_4_description')
        },
        {
          image: require('@/assets/img/icons/blue/cash_no_check.png'),
          title: this.$ml.get('how_it_works_step_5_title'),
          text: this.$ml.get('how_it_works_step_5_description')
        },
        {
          image: require('@/assets/img/icons/blue/wink.png'),
          title: this.$ml.get('how_it_works_step_6_title'),
          text: this.$ml.get('how_it_works_step_6_description')
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
